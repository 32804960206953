.search-wrap {
    margin: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.search-wrap .MuiGrid-container {
    align-items: center; /* Aligns items vertically center */
}

.search-wrap .MuiTextField-root {
    margin-right: 10px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .search-wrap .MuiTextField-root, .search-wrap .MuiButton-root {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .search-wrap .MuiGrid-container {
        flex-direction: column;
        align-items: center;
    }
}
