/* Footer CSS */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  padding-top: 16px;
  text-align: left;
  background-color: #23395D;
  color: white;
  box-shadow: 0 -2px 4px -1px rgba(0,0,0,0.2), 0 -4px 5px 0 rgba(0,0,0,0.14), 0 -1px 10px 0 rgba(0,0,0,0.12); /* Optional: adds shadow to the top of the footer */
}

/* If you're using global CSS, the span style may affect other spans, consider scoping it or using a class */
.footer span {
  margin-right: 10px;
}
